import Form from "@components/Email";
import Image from "@components/Image";
import Link from "next/link";
import { Content, Row } from "pages";

const Social = () => {
    return (
        <div>

            <div className="flex gap-8">
                <a className="m-auto" href="https://www.linkedin.com/company/altostruct/">
                    <svg className="md:w-24 w-16" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 48 48">
                        <path fill="#0288D1" d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"></path><path fill="#FFF" d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"></path>
                    </svg>
                </a>
                <a className="m-auto" href="https://partners.amazonaws.com/partners/0018W000025sRvpQAE/Altostruct%20AB">
                    <Image className="md:w-24 w-16" alt="" width={64} height={64} src={"/images/v2/partner_badge.png"}></Image>

                </a>
            </div>
        </div >
    );
}

const Footer = () => {
    return <div className="w-screen mt-32 border-t-2 ">
        <Content className="py-12 text-gray-black">
            <Row className="">
                <div className="flex-1 gap-3 flex flex-col">
                    {/* <a className=" text-6xl underline pb-8">
                        Senaste nyheter
                    </a> */}
                    <Link href="/contact">
                        <p className="text-3xl md:text-4xl underline">
                            Kontakta oss
                        </p>
                    </Link>

                    <p onClick={() => {
                        const { ml } = window as any
                        ml('show', 'SJjIj6', true)
                    }} className="text-3xl md:text-4xl underline cursor-pointer">
                        Nyhetsbrev
                    </p>
                </div>
                <div className="mt-auto">
                    <Social></Social>
                </div>

            </Row>


        </Content>
    </div>
};

export default Footer;